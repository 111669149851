.App {
  text-align: center;
}

h1 {
  padding: 0 1rem;
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

h2 {
  font-size: 16px;
}

body {
  background-color: #eee;
  color: #282c34;
  font-family: Tahoma;
}

.square {
  border: solid black 1px;
  width: 60vw;
  height: 60vw;
  max-height: 700px;
  max-width: 700px;
  margin: 0 auto 0.7rem;
  position: relative;
}

  @media (max-width: 700px) {
    .square {
      width: 95vw;
      height: 95vw;
      margin-bottom: 2rem;
    }
  }

.verdansk {
  background: url("MapEdit.jpg") #5e0000 no-repeat;
  background-size: 105%;
  background-position: 153% 51%;
}

  .verdansk .myCanvas {
    position: absolute;
    left: 6.5%;
    top: 10.5%;
    height: 90.25%;
    width: 90.1%;
  }

.caldera {
  background: url("caldera2.jpg") black no-repeat;
  background-size: 98%;
  background-position: 143% 163%;
}

  .caldera .myCanvas {
    position: absolute;
    left: 12%;
    top: 7%;
    height: 80%;
    width: 80%;
  }

  .caldera .unit {
    width: 11.5%;
    height: 11.5%;
  }

.fortunesKeep {
  background: url("FortunesKeepEdit.jpg") black no-repeat;
  background-size: 98%;
  background-position: 143% 163%;
  z-index: -1000;
}

  .fortunesKeep .myCanvas {
    position: absolute;
    left: 0%;
    top: 24.1%;
    height: 95.2%;
    width: 95.2%;
  }

  .fortunesKeep .unit {
    width: 11.5%;
    height: 11.5%;
  }
  
.rebirth {
  background: url("RebirthEdit.jpg") black no-repeat;
  background-size: 110%;
  background-position: 15% 60%;
}

  .rebirth .myCanvas {
    position: absolute;
    left: 12.7%;
    top: 8.9%;
    height: 91%;
    width: 81%;
  }

  /* only using this because background-size is NOT 100% */
  .rebirth .unit {
    width: 19.7%;
    height: 17.63%;
  }

button {
  color: white;
  background-color: #333;
}

button.generate, button.clear {
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  margin: 1rem auto;
  display: block;
}

.dark button.generate {
  border: 1px solid #eee;
}

.bottom {
  margin: 5rem 0;
  font-size: 0.8rem;
}

.center-my-children {
  margin-top: 1.3rem;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

label {
    vertical-align: 5px;
    margin-left: 7px;
}

    label.mapLabel {
      vertical-align: middle;
      margin-right: 1rem;
    }
    
button {
  min-width: 270px;
}

  button:hover {
    cursor: pointer;
  }

.unit {
  border: solid 0.5px rgba(255, 255, 255, 0.6);
  display: inline-block;
  width: 14.285%;
  height: 14.28%;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 100);
  position: relative;
}

  .unit span {
    margin-top: calc(50% - 14px);
    display: block;
    font-size: 1.4rem;
    color:rgba(255, 255, 255, 0);
  }

span.axis {
  color: white;
  position: absolute;
}

span.x {
  top: 0;
  margin-top: calc(-50% + -10px);
  left: calc(50% - 6px);  
}

  .rebirth span.x {
    top: 0;
    margin-top: calc(-42% + -10px);
    left: calc(50% - 6px);  
  }

span.y {
  margin-top: calc(50% - 12px);
  left: -46%;
}

  .rebirth span.y {
    margin-top: calc(45% - 9px);
    left: -57%;
  }

  .fortunesKeep span.y {
    left: -34%;
  }

.unit.highlighted, 
.unit.highlighted.bad {
  background-color: rgba(202, 167, 0, 0.63);
}

.highlighted span.unitLabel {
  color:rgba(0, 0, 0, 1);
}

.unit.bad {
  background-color: rgba(19, 32, 192, 0.20);
}

.unit.badbad {
  background-color: #ff000436;
}

.updates { 
  text-align: left;
  padding: 1rem;
}

.tabsareinmybelly {
  margin-bottom: 1rem;
  border-bottom: 1px solid black;
}

.tabs {
  width: 60vw;
  max-width: 700px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 auto;
  list-style: none;
  padding: 0;
}

  @media (max-width: 700px) {
    .tabs {
      width: 95vw;
    }
  }

  .tabs li {
    padding: 1rem 0;
    border: 1px solid black;
    border-bottom: 1px solid #eee;
  }

  .tabs li:hover {
    cursor: pointer;
  }

  .tabs .selected {
    font-weight: bold;
    margin-bottom: -1px;
    border-bottom: 4px solid #eee;
    text-decoration: underline;
  }

  body.dark {
    background-color: #0e0e0e;
    color: #eee;
  }

  .dark a {
    color: #fff;
  }

.clear {
    background-color: #820000;
    color: #fff;
    border-radius: 0%;
}

.clear.all {
  background-color: #1e1e88;
  font-size: 1rem;
}

select.mapSelect {
  padding: 0.4rem 1rem;
  font-size: 1rem;
}

.simpleMapSelector {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}